<template>
  <div>
    <div class="bg">
      <img src="@/assets/images/solution_bg.png" alt="">
      <div class="content">
        <div class="title">解决方案</div>
        <div class="desc">盘点更高效</div>
        <!-- <div class="action">免费试用</div> -->
      </div>
    </div>
    <div class="predicament">
      <div class="title">企业面临的困局</div>
      <div class="content h">
        <div class="item-wrap right-m">
          <div class="name">管理难度大、盘点耗时长</div>
          <div class="desc">资产数量多、位置分数、管理难度大，没人能准确掌握资产状况。有多少、在哪里、谁用看？正常还是已损坏？履历是否完整？是否有闲置和重复购置？ 传统方式管理固定资产（如用Excel）繁琐容易出错。盘点工作量大、耗时长，导致人员工作不仔细，容易敷衍。</div>
        </div>
        <img src="@/assets/images/solution_bg_1.png" alt="">
      </div>
      <div class="content">
        <img src="@/assets/images/solution_bg_2.png" alt="">
        <div class="item-wrap left-m">
          <div class="name">账实不符、公司间相互占用</div>
          <div class="desc">固定资产账实不符。按照财务要求，只有一定价值之上的资产才入财务账，实际管理中需要管理许多不太值钱的物品，导致财务帐面资产和行政管理的事物资产不能应对。</div>
        </div>
      </div>
      <div class="content">
        <div class="item-wrap right-m">
          <div class="name">管理行为被动、管理难</div>
          <div class="desc">传统的资产管理行为一部分发生在使用信息发生变化时，一部分发生在实物信息发生变化时，企业即使制定了资产管理标准，借助信息化工具跟进，也难以帮助管理人员实时主动掌握人，资产准备情况，管理行为较为被动。</div>
        </div>
        <img src="@/assets/images/solution_bg_3.png" alt="">
      </div>
    </div>
    <div class="all-solution">
      <div class="title">全部解决方案</div>
      <div class="tab-container">
        <div class="horizontal-line"></div>
        <a-tabs class="tab-wrap" default-active-key="1">
          <a-tab-pane key="1" tab="移动APP方案">
            <div class="app-solution-wrap">
              <div class="item-wrap">
                <img src="@/assets/images/solution_img_1.png" alt="">
                <div class="text-wrap">
                  <div class="text">移动导入</div>
                  <div>盘点/巡检/维修/审批/业务工单</div>
                </div>
              </div>
              <div class="item-wrap">
                <img src="@/assets/images/solution_img_2.png" alt="">
                <div class="text-wrap">
                  <div class="text">线上协作</div>
                  <div>数据同步后台，多段协作更便捷</div>
                </div>
              </div>
              <div class="item-wrap">
                <img src="@/assets/images/solution_img_3.png" alt="">
                <div class="text-wrap">
                  <div class="text">实时查看</div>
                  <div>实时查看盘点、实时下载数据</div>
                </div>
              </div>
            </div>
          </a-tab-pane>
          <a-tab-pane key="2" tab="无源RFID方案">

          </a-tab-pane>
          <a-tab-pane key="3" tab="有源RFID方案">

          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
    <div class="kefu">
      <img src="@/assets/images/kefu.png" alt="">
    </div>
    <Footer />
  </div>
</template>

<script>

import Footer from '@/components/Footer'

export default {
  components: {
    Footer
  },
  data() {
    return {

    }
  },
  mounted() {

  }
}
</script>

<style lang="less" scoped>
.bg{
  position: relative;
  img{
    width: 100%;
    display: block;
  }
  .content{
    position: absolute;
    top: 45%;
    left: 25%;
    .title{
      font-size: 4.6rem;
      font-weight: bold;
      color: #fff;
    }
    .desc{
      font-size: 2.4rem;
      font-weight: 100;
      color: #fff;
      margin: 5px 0 40px;
    }
    .action{
      width: 120px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background-image: linear-gradient(to right, #3538FB, #92F0FE);
      border-radius: 8px;
      font-size: 1.6rem;
      color: #fff;
      cursor: pointer;
    }
    .action:hover{
      background-image: linear-gradient(to right, #2225DA, #92D3FE);
    }
  }
}
.predicament{
  padding-top: 6rem;
  .title{
    font-size: 3.6rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 4.5rem;
  }
  .content{
    display: flex;
    align-items: center;
    justify-content: center;
    .item-wrap{
      width: 60rem;
      .name{
        font-size: 2.4rem;
      }
      .desc{
        margin-top: 2.4rem;
        font-size: 1.6rem;
        color: #80879A;
        line-height: 2.4rem;
      }
    }
    .right-m{
      padding-right: 6rem;
    }
    .left-m{
      padding-left: 6rem;
    }
    img{
      width: 50rem;
      display: block;
    }
  }
  .h{
    margin: 2.5rem 0;
  }
}
.all-solution{
  background-color: #F0F8FE;
  padding: 6rem 0;
  margin: 6rem 0;
  .title{
    font-size: 3.6rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 3.6rem;
  }
  .tab-container{
    width: 1200px;
    margin: 0 auto;
    position: relative;
    .horizontal-line{
      width: 100%;
      height: 1px;
      background-color: #bfd2df;
      position: absolute;
      top: 49px;
    }
    .tab-wrap{
      .app-solution-wrap{
        display: flex;
        justify-content: center;
        .item-wrap{
          display: flex;
          flex-direction: column;
          align-items: center;
          img{
            margin: 10px 10px 20px;
            background-color: #dae6ef;
          }
          .text-wrap{
            display: flex;
            flex-direction: column;
            align-items: center;
            .text{
              font-size: 1.8rem;
            }
          }
        }
      }
    }
  }
}
/deep/ .ant-tabs-nav .ant-tabs-tab{
  font-size: 1.8rem;
}
/deep/ .ant-tabs-bar{
  border-bottom: 0 solid #e8e8e8;
}
/deep/ .ant-tabs-ink-bar{
  height: 4px;
}
.kefu{
  display: flex;
  justify-content: center;
  img{
    width: 1200px;
  }
}
</style>
